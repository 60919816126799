import useQueryCache from 'web/hooks/useQueryCache';
import useSanityQuery from 'web/hooks/useSanityQuery';
import { FaqPage } from './types';

const query = '*[_type == "faqPage"][0]';

const useCachedFaqQuery = (initialData?: FaqPage) => {
  const result = useSanityQuery<FaqPage>(query);
  return useQueryCache(`faq`, result, initialData);
};

export default useCachedFaqQuery;
