import { PortableText } from '@portabletext/react';
import React from 'react';
import { Helmet } from 'react-helmet-async';
import { Navigate, useLocation } from 'react-router';
import styled from 'styled-components';
import CenterPageContainer from 'web/components/CenterPageContainer';
import Container from 'web/components/Container';
import { LinkStyled, LinkUnstyled } from 'web/components/elements';
import PageLoadingFallback from 'web/components/PageLoadingFallback';
import { defaultSerializers } from 'web/components/portable-text/default-serializers';
import ScreenTracker from 'web/components/ScreenTracker';
import ScrollIntoViewByHash from 'web/components/ScrollIntoViewByHash';
import ScrollToTopOnMount from 'web/components/ScrollToTopOnMount';
import TextPageLayout from 'web/components/TextPageLayout';
import useInitialRouteData from 'web/hooks/useInitialRouteData';
import themeConstants from 'web/styles/themeConstants';
import themeVars from 'web/styles/themeVars.css';
import { FaqPage } from './types';
import useCachedFaqQuery from './useCachedFaqQuery';

const Title = styled.h1`
  margin-top: 48px;
  margin-bottom: 12px;
  ${themeConstants.media.md} {
    ${themeConstants.typography.hero}
  }
`;

const SubTitle = styled.p`
  margin-bottom: 40px;
  color: ${themeVars.color.halfMuted};
`;

const FaqMainSection = styled.section`
  margin-bottom: 60px;
`;

const FaqEntry = styled(ScrollIntoViewByHash)`
  padding-top: 24px;
  padding-bottom: 24px;

  border-top: 1px solid ${themeConstants.borders.light};
  :last-child {
    border-bottom: 1px solid ${themeConstants.borders.light};
  }
`;

const FaqEntryTitle = styled.div`
  display: flex;
  justify-content: space-between;

  cursor: pointer;
`;

const Chevron = styled(({ className }: { className?: string }) => (
  <svg className={className} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12 15.5a.997.997 0 01-.707-.293l-4-4a.999.999 0 111.414-1.414l3.305 3.305 3.293-3.18a1 1 0 011.39 1.44l-4 3.861A.997.997 0 0112 15.5z"
      fillRule="evenodd"
    />
  </svg>
))<{ up?: boolean }>`
  fill: ${themeConstants.palette.gray[600]};
  width: 24px;
  height: 24px;

  ${({ up }) => up && 'transform: scaleY(-1)'};

  transition: transform 0.3s ease-in;
`;

const FaqEntryBody = styled.div<{ expanded?: boolean }>`
  margin-top: 24px;

  > :first-child {
    margin-top: 0;
  }

  > :last-child {
    margin-bottom: 0;
  }

  display: ${({ expanded }) => (expanded ? 'block' : 'none')};
`;

const FaqEntryHeading = styled.h4`
  margin-top: 0;
  margin-bottom: 0;

  ${themeConstants.typography.regular}
  font-weight: bold;
`;

const FaqExtraSection = styled.section`
  margin-bottom: 80px;

  display: grid;
  row-gap: 24px;

  ${themeConstants.media.sm} {
    grid-template-columns: repeat(2, minmax(0, 382px));
    column-gap: 48px;
    justify-content: center;
  }
`;

const FaqExtraItem = styled.div`
  border-radius: ${themeConstants.borderRadius.sm};
  border: 2px solid ${themeConstants.borders.light};
  padding: 32px;
`;

const expandAll = true;

const Faq = () => {
  const location = useLocation();
  const expandedKey = location.hash?.replace('#', '') || '';

  const initialFaq = useInitialRouteData<FaqPage>();
  const [faq, loading, error] = useCachedFaqQuery(initialFaq);

  return (
    <>
      <Helmet title="FAQs" />
      <ScreenTracker screenName="Faq" />
      <ScrollToTopOnMount />
      <TextPageLayout>
        {loading && <PageLoadingFallback />}
        {!loading && error && (
          <CenterPageContainer>
            <div style={{ textAlign: 'center' }}>
              <h1>Error</h1>
              <p>Something went wrong. Please try again later.</p>
            </div>
          </CenterPageContainer>
        )}
        {!loading && !error && !faq && <Navigate to="/" />}
        {!loading && !error && faq && (
          <Container narrow>
            <FaqMainSection>
              <Title data-cy="faq-title">{faq.title}</Title>
              <SubTitle>{faq.subtitle}</SubTitle>
              {faq.entries.map(({ slug: { current: slug }, title, body }) => (
                <FaqEntry id={slug} key={slug}>
                  <LinkUnstyled to={`#${slug}`} replace style={{ color: 'inherit' }}>
                    <FaqEntryTitle>
                      <FaqEntryHeading>{title}</FaqEntryHeading>
                      {!expandAll && <Chevron up={expandedKey === slug} />}
                    </FaqEntryTitle>
                  </LinkUnstyled>
                  <FaqEntryBody expanded={expandAll || expandedKey === slug}>
                    <PortableText value={body} components={defaultSerializers} />
                  </FaqEntryBody>
                </FaqEntry>
              ))}
            </FaqMainSection>
            <FaqExtraSection>
              <FaqExtraItem>
                <FaqEntryHeading>Pricing</FaqEntryHeading>
                <p>Compare our pricing plans and choose the right one for you</p>
                <LinkStyled to="/pricing">See pricing plans</LinkStyled>
              </FaqExtraItem>
              <FaqExtraItem>
                <FaqEntryHeading>Need more help?</FaqEntryHeading>
                <p>Get in touch and we will answer all of your questions</p>
                <LinkStyled to="/contact">Contact us</LinkStyled>
              </FaqExtraItem>
            </FaqExtraSection>
          </Container>
        )}
      </TextPageLayout>
    </>
  );
};

export default Faq;
