import { useEffect, useMemo } from 'react';
import useLoadingState from './firebase/useLoadingState';
import useSanity from './useSanity';

const useSanityQuery = <Result>(query: string, params?: Record<string, unknown>) => {
  const { value, setValue, setError, error, loading } = useLoadingState<Result, Error>();
  const sanity = useSanity();
  useEffect(() => {
    const fetch = async () => {
      try {
        const page = await sanity.fetch<Result>(query, params);
        setValue(page);
      } catch (err) {
        setError(err);
      }
    };
    void fetch();
  }, [params, query, sanity, setError, setValue]);
  return useMemo(() => [value, loading, error] as const, [error, loading, value]);
};

export default useSanityQuery;
